.content-info {
  margin-top: 40px;
  position: relative;
  display: flex;
  align-items: center;

  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.main-footer {
  background-color: #ebebeb;
  padding: 20px 0;

  @include media-breakpoint-down(md) {
    text-align: center;
    padding: 2rem 0;
  }

  .row {
    align-items: center;
  }

  .copyrights {
    margin: 0;
    font-size: 14px;
    text-transform: uppercase;
    a {
      color: $primary-color;
      font-weight: $fw-bold;
    }
  }

  .founder {
    border-right: 1px solid #999999;

    @include media-breakpoint-down(md) {
      border-right: 0;
      margin-top: 15px;
    }

    img {
      margin: auto;
      display: block;
      mix-blend-mode: darken;
    }
    ul {
      padding: 0;
      margin-top: 15px;
      list-style: none;
      text-align: center;
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 0;
    }
  }

  .info {
    ul {
      padding: 0;
      margin-top: 15px;
      list-style: none;
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 0;
      &:last-child {
        a {
          text-decoration: underline;
        }
      }
    }
    
    a {
      color: currentColor;
    }
  }
}