.banner {
  padding: 15px 0;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background-color: $white;
  transition: box-shadow 350ms $ease-out;
  z-index: 100;

  .admin-bar & {
    top: 32px;
  }

  &.cast-shadow {
    box-shadow: 0 0 10px rgba(0,0,0,.1);
  }

  @include media-breakpoint-down(md) {
    padding: 15px;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @include media-breakpoint-down(lg) {
      max-width: 100%;
    }
  }

  .nav {
    font-size: 14px;
    margin-right: 0;
    margin-left: auto;
    text-transform: uppercase;

    li {
      &:not(:last-child) {
        margin-right: 20px;
      }
      &.active {
        a {
          color: $white;
          background-color: $primary-color;
          &:hover {
            background-color: lighten($primary-color, 5%);
          }
        }

        .page-template-template-landing & {
          a {
            background-color: $blue-color;
            &:hover {
              background-color: lighten($blue-color, 5%);
            }
          }
        }
      }
    }

    a {
      padding: 5px 10px;
      color: $text-color;
      font-weight: $fw-regular;
      transition: color 350ms $ease-out;
      position: relative;
      &:hover {
        color: $white;
        text-decoration: none;
        &:after {
          opacity: 1;
          height: 100%;
        }
      }
      &:after {
        content: "";
        position: absolute;
        opacity: 0;
        height: 0;
        width: 100%;
        left: 0;
        top: 0; bottom: 0;
        margin: auto;
        transition: opacity 350ms $ease-out, height 350ms $ease-out;
        backface-visibility: hidden;
        background-color: $primary-color;
        z-index: -1;
      }
    }
  }
}

.brand {
  width: 73px;
  height: 60px;
  display: inline-block;
  overflow: hidden;
}

.nav-item {
  h2 {
    font-size: 16px;
    text-transform: uppercase;
  }


}

.nav-link {
  color: currentColor;
  padding: .5rem 1.35rem .5rem 1rem;
  &:hover:not(.active) {
    color: $primary-color;
  }
  &.active {
    color: $white;
    background-color: $primary-color;
  }
}