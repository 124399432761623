.c-doctor-box {}

.c-doctor-box__header  {
  color: $white;
  margin-top: 60px;
  padding: 2.5rem 0;
  background-color: $blue-color;
  text-transform: uppercase;
  display: flex;
  justify-content: center;

  .row {
    align-items: center;
  }

  img {
    border-radius: 50%;
    margin-right: 3rem;
    @include media-breakpoint-down(md) {
      margin: auto;
      display: block;
      max-width: 100%;
      padding: 0 2rem;
    }
  }
}

.c-doctor-box__header__content {
  max-width: 520px;
  margin-left: 3rem;

  @include media-breakpoint-down(md) {
    margin: 0;
    padding: 0 2rem;
    max-width: 100%;
  }

  h2 {
    font-size: 26px;
    font-weight: $fw-black;
    letter-spacing: 5px;
  }
  ul {
    list-style: none;
    margin-bottom: 1rem;
    border-bottom: 3px solid white;
    padding: 0 0 1rem;
    li {
      font-size: 20px;
      letter-spacing: 4px;
    }
  }
  p {
    letter-spacing: 3px;
    max-width: 70%;
    @include media-breakpoint-down(md) {
      max-width: 100%;
    }
  }
}

.c-doctor-box__content {
  padding: 4rem;
  background-color: #ebebeb;
  @include media-breakpoint-down(md) {
    padding: 2rem;
  }
  p {
    margin: 0;
    font-size: 18px;
  }
}

.c-doctor-box__gallery {
  padding: 4rem;
  position: relative;
  @include media-breakpoint-down(md) {
    padding: 2rem;
  }
}

.c-doctor-box__gallery__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 5;
  @include media-breakpoint-down(md) {
    flex-direction: column;
  }
}

.c-doctor-box__gallery__item {
  font-size: 0;
  display: inline;
  padding: 10px;
  img {
    font-size: 0;
    max-width: 100%;
    height: auto;
  }
}

.c-doctor-box__gallery__bg {
  position: absolute;
  object-fit: cover;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}