// Search form
.search-form {
  @extend .form-inline;
}
.search-form label {
  font-weight: normal;
  @extend .form-group;
}
.search-form .search-field {
  @extend .form-control;
}
.search-form .search-submit {
  @extend .btn;
  @extend .btn-secondary;
}

.form-control {
  border-radius: 0;
  border-color: white;
  outline: 0;
  text-transform: uppercase;
  font-size: 14px;
  padding: .5rem .75rem;
}

textarea.form-control {
  height: 100px;
}

.form-control:focus {
  border-color: #41a7a7;
  box-shadow: none;
}

.form-group {
  margin-bottom: 12px;
  position: relative;
}

.form-text {
  color: currentColor;
  text-transform: uppercase;
  font-size: 10px;
  line-height: 4px;
  margin: 0 0 0 auto;
  text-align: right;
  width: calc(100% - 150px);
}

.wpcf7-form-control-wrap {
  width: 100%;
  display: block;
  position: relative;
}

span.wpcf7-not-valid-tip {
  font-size: 9px;
  position: absolute;
  text-transform: uppercase;
  top: 5px;
  right: 5px;
}

div.wpcf7-response-output {
  position: absolute;
  background: white;
  width: 100%;
  left: 0;
  bottom: 0;
  margin: 0;
  text-align: center;
  transform: translateY(100%);
  border-radius: 0 0 8px 8px;
  text-transform: uppercase;
  font-size: 12px;
  padding: 10px 0;

  &.wpcf7-validation-errors,
  &.wpcf7-acceptance-missing {
    border-color: #ef8c8c;
    background: #ffb6c1;
    color: #d66363;
    box-shadow: 0 5px 20px rgba(#d66363, 0.5);
  }

  &.wpcf7-mail-sent-ok {
    border: 2px solid #94da76;
    background: #dbf9da;
    color: #4dc14c;
    box-shadow: 0 5px 20px rgba(#4dc14c, 0.3);
  }
}

div.wpcf7 .ajax-loader {
  width: 16px;
  height: 44px;
  background-color: white;
  background-repeat: no-repeat;
  background-position: center;
  padding: 0 15px;
  border: none;
  margin: 0;
  vertical-align: middle;
  border-radius: 0 8px 8px 0;
}

input.wpcf7-not-valid,
textarea.wpcf7-not-valid {
  border-color: #f5aaaa;
  background-color: #fdedef;
}