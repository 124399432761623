.c-full-box {
  @extend .c-static-box;
  justify-content: flex-start;
  @include media-breakpoint-down(lg) {
    justify-content: center;
  }
}

.c-full-box__content {
  @extend .c-static-box__content;

  h2 {
    border-bottom-color: $secondary-color;
    span {
      font-size: 16px;
      display: block;
      font-weight: 900;
      letter-spacing: normal;
      margin-bottom: 16px;
    }
  }

  p {
    font-size: 20px;
  }
}