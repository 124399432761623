.c-info-box {
  height: 100%;
  color: $white;
  text-transform: uppercase;
  background-image: linear-gradient(-210deg, #66cccc 0%, #339999 100%);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.c-info-box__content {
  padding: 3rem;
}

.c-info-box__title {
  font-size: 16px;
  font-weight: $fw-black;
  letter-spacing: 3.2px;
  margin-bottom: 30px;
  &.dots {
    &:before { content: "• "; }
    &:after { content: " •"; }
  }
}

.c-info-box__span {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
  span {
    font-size: 20px;
    margin-top: 10px;
    text-transform: none;
    display: block;
  }
  a {
    display: block;
    font-size: 20px;
    color: currentColor;
    font-weight: $fw-bold;
    text-transform: none;
    &:hover {
      text-decoration: none;
    }
  }
}