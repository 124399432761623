// Grid settings
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

$container-max-widths: (
        sm: 540px,
        md: 720px,
        lg: 1024px,
        xl: 1300px
) !default;

$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 1024px,
        xl: 1300px
) !default;

// Colors
$primary-color:   #339999;
$secondary-color: #ffcc00;
$text-color:      #333333;
$blue-color:      #336699;

// Font weights
$fw-light: 300;
$fw-regular: 400;
$fw-bold: 700;
$fw-black: 900;

// Easings
$ease:     cubic-bezier(0.4, 0.0, 0.2, 1);
$ease-in:  cubic-bezier(0.4, 0.0, 1, 1);
$ease-out: cubic-bezier(0.0, 0.0, 0.2, 1);
$duration: 250ms;