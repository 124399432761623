#gmaps {
  width: 100%;
  height: 560px;
}

.logo-medyk {
  width: 73px;
  height: 60px;
  background: url(../images/medyk.png) center no-repeat;
  background-size: contain;
  display: block;
  margin: auto;
}

.logo-jantar {
  width: 73px;
  height: 60px;
  background: url(../images/logo.png) center no-repeat;
  display: block;
  margin: auto;
}

.si-content-wrapper {
  padding: 10px 30px;
}

.si-wrapper-top {
  margin-top: -10px;
}

.si-has-border .si-content-wrapper {
  border: 0;
}

.si-shadow-frame {
  box-shadow: none;
}

[class*=si-pointer-bg] {
  border-width: 25px !important;
}

[class*=si-shadow-inner-pointer] {
  box-shadow: none;
}

.si-pointer-border-top {
  border-top-color: transparent;
}

.si-close-button {
  display: none;
}

.gmnoprint img {
  display: none !important;
  visibility: hidden !important;
  opacity: 0 !important;
}

.gmnoprint {
  opacity: 0 !important;
  display: none !important;
  visibility: hidden !important;
}