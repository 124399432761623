.c-static-box {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  color: $white;

  @include media-breakpoint-down(xs) {
    flex-direction: column;
  }

  img {
    max-width: 100%;
    object-fit: cover;
  }
}

.c-static-box__content {
  position: absolute;
  padding: 2.5rem 1.5rem;

  @include media-breakpoint-down(md) {
    text-align: center;
    padding: 2.5rem 1rem;
    width: 100%;
  }

  @include media-breakpoint-down(xs) {
    position: static;
    background-image: linear-gradient(-210deg,#6cc,#399);
    order: 2;
  }

  h2 {
    display: inline-block;
    font-size: 26px;
    font-weight: $fw-black;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 15px;
    border-bottom: 5px solid $primary-color;
    text-transform: uppercase;
    letter-spacing: 5px;

    @include media-breakpoint-down(lg) {
      font-size: 20px;
      text-align: center;
    }

    @include media-breakpoint-down(md) {
      padding-left: 0;
      padding-right: 0;
      font-size: 16px;
    }
  }

  a {
    color: currentColor;
    &:hover {
      text-decoration: none;
    }
  }

  p,
  ul {
    padding: 0;
    font-size: 16px;
    line-height: 1.7;
    &:empty {
      display: none;
    }
  }

  ul {
    padding-left: 1rem;
    li {
      &:not(:first-child) {
        padding-top: 10px;
      }
    }
  }
}

.c-static-box__content__text {
  display: block;
  padding: 1rem 0 1rem 1.5rem;
  @include media-breakpoint-down(lg) {
    display: none;
  }
}

.c-static-box__content__btn {
  padding: 1rem;
  padding-bottom: 0;
  text-align: center;
  &--to-right {
    text-align: right;
    @include media-breakpoint-down(lg) {
      text-align: center;
    }
  }
}

.c-static-box__free {
  font-size: 11px;
  text-transform: uppercase;
  color: $primary-color;
  background-color: $secondary-color;
  font-weight: $fw-black;
  padding: 3px 20px;
  border-radius: 16px;
  letter-spacing: 1px;
  position: absolute;
  top: 0; left: 3rem;
  @include media-breakpoint-down(lg) {
    top: 0;
    left: 0;
    right: 0;
    width: 170px;
    text-align: center;
    margin: auto;
  }
  @include media-breakpoint-down(xs) {
    top: 1rem;
  }
}

.c-static-box__bg {
  max-width: 100%;
}