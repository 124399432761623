.c-prowadzacy-box__wrapper {
  margin-top: 60px;
}

.c-prowadzacy-box {
  margin-bottom: 40px;

  img {
    max-width: 100%;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
}

.c-prowadzacy-box__content {
  color: $white;
  background-color: $primary-color;
  text-align: center;
  padding: 1rem;

  h3 {
    font-size: 20px;
    font-weight: $fw-bold;
  }
  p {
    font-size: 15px;
    font-weight: $fw-regular;
    text-transform: uppercase;
    line-height: 1.25;
    max-width: 210px;
    margin: auto;
  }
}