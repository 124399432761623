body {
  background-color: #ffffff;
  font-family: 'Lato', sans-serif;
  padding-top: 100px;
}

::selection {
  color: $primary-color;
  background: rgba($gray-300, 0.75);
}

.wrap.container {
  @include media-breakpoint-up(xl) {
    padding: 0;
  }
  .main,
  .container {
    padding: 0;
  }
}

.main {
  .row {
    &:not(:first-child) {
      margin-top: 40px;
    }

    @include media-breakpoint-down(md) {
      [class^=col-] {
        &:not(:first-child) {
          margin-top: 40px;
        }
      }
    }
  }
}