// Landing Page - Header

.lp-header {
  color: $white;
  background-color: $blue-color;
  background-image: linear-gradient(-210deg, #336699 0%, #66cccc 100%);
  padding: 6rem;
}

.lp-header__content {
  text-transform: uppercase;
  @include media-breakpoint-down(md) {
    text-align: center;
  }

  h1 {
    font-size: 26px;
    letter-spacing: 5px;
    font-weight: $fw-black;
    line-height: 1.55;
    max-width: 840px;
    margin-bottom: 0;
    @include media-breakpoint-down(md) {
      font-size: 20px;
    }
  }
  p {
    font-size: 20px;
    letter-spacing: 4px;
    @include media-breakpoint-down(md) {
      font-size: 18px;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  .col {
    position: relative;
    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 0; bottom: 0;
      width: 60px; height: 4px;
      background-color: $white;
      margin: auto;
      left: -70px;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}

// Landing Page Content

.lp-content {

  h3 {
    font-size: 26px;
    margin-top: 4rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    color: $blue-color;
    font-weight: $fw-black;
    letter-spacing: 5px;
    &:first-of-type {
      margin-top: 1rem;
    }
  }
  h4 {
    font-size: 18px;
    color: $blue-color;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-weight: $fw-bold;
    &:not(:first-of-type) {
      margin-top: 2rem;
    }
  }
  p {
    font-size: 18px;
    font-weight: $fw-regular;
  }
  blockquote {
    color: $white;
    margin: 2rem 0;
    background: url(../images/icon-info.png) left 40px center no-repeat, #336699;
    text-transform: uppercase;
    padding: 3rem 2rem 3rem 140px;
    p {
      margin: 0;
    }
  }
  strong {
    font-weight: $fw-bold;
  }
  ol {
    font-size: 18px;
    font-weight: $fw-regular;
    counter-reset: section;
    list-style: none;
    padding: 0;
    padding-left: 31px;
    li {
      position: relative;
      padding-left: 20px;
      &:not(:first-child) {
        margin-top: 10px;
      }
      &:before {
        top: 5px;
        left: -30px;
        position: absolute;
        counter-increment: section;
        content: counter(section);
        font-size: 11px;
        font-weight: $fw-light;
        background-color: $blue-color;
        width: 18px; height: 18px;
        display: inline-block;
        border-radius: 50%;
        text-align: center;
        line-height: 18px;
        color: white;
      }
      ol {
        padding-left: 5px;
        li {
          margin: 0;
          &:not(:first-child) {
            margin-top: 0;
          }
          &:before {
            content: "";
            width: 10px;
            height: 10px;
            margin-right: 10px;
            position: static;
          }
        }
      }
    }
  }
  ul {
    font-size: 18px;
    font-weight: $fw-regular;
    counter-reset: section;
    list-style: none;
    padding: 0;
    padding-left: 31px;
    li {
      position: relative;
      padding-left: 20px;
      &:not(:first-child) {
        margin-top: 10px;
      }
      &:before {
        top: 7px;
        left: -26px;
        position: absolute;
        content: "";
        font-size: 11px;
        font-weight: $fw-light;
        background-color: $blue-color;
        width: 10px; height: 10px;
        display: inline-block;
        border-radius: 50%;
        text-align: center;
        line-height: 10px;
        color: white;
      }
      ul {
        padding-left: 5px;
        li {
          margin: 0;
          &:not(:first-child) {
            margin-top: 0;
          }
        }
      }
    }
  }
}

// Gallery
.gallery-grid {}

.gallery-item {
  float: left;
  font-size: 0;
  display: inline-block;
  margin-bottom: 20px;
  img {
    width: auto;
    max-width: 100%;
    height: auto;
  }
}