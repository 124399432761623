.p-content {

  h2, h3 {
    margin-top: 4rem;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    color: $primary-color;
    font-weight: $fw-black;
    letter-spacing: 5px;
    &:first-of-type {
      margin-top: 1rem;
    }
  }
  h2 {
    font-size: 30px;
  }
  h3 {
    font-size: 26px;
  }
  h4 {
    font-size: 18px;
    color: $primary-color;
    text-transform: uppercase;
    margin-bottom: 1rem;
    font-weight: $fw-bold;
    &:not(:first-of-type) {
      margin-top: 2rem;
    }
  }
  p {
    font-size: 20px;
    font-weight: $fw-regular;
  }
  blockquote {
    color: $white;
    margin: 2rem 0;
    background: url(../images/icon-info.png) left 40px center no-repeat, $primary-color;
    padding: 3rem 2rem 3rem 140px;
    font-size: 18px;
    @include media-breakpoint-down(sm) {
      background: url(../images/icon-info.png) top 40px center no-repeat,#399;
      padding: 140px 2rem 3rem 2rem;
      text-align: center;
    }
    p {
      margin: 0;
    }
  }
  strong {
    font-weight: $fw-bold;
  }
  ol {
    font-size: 20px;
    font-weight: $fw-regular;
    counter-reset: section;
    list-style: none;
    padding: 0;
    padding-left: 31px;
    li {
      position: relative;
      padding-left: 20px;
      &:not(:first-child) {
        margin-top: 10px;
      }
      &:before {
        top: 7px;
        left: -30px;
        position: absolute;
        counter-increment: section;
        content: counter(section);
        font-size: 11px;
        font-weight: $fw-light;
        background-color: $primary-color;
        width: 18px; height: 18px;
        display: inline-block;
        border-radius: 50%;
        text-align: center;
        line-height: 18px;
        color: white;
      }
      ol {
        padding-left: 5px;
        li {
          margin: 0;
          &:not(:first-child) {
            margin-top: 0;
          }
          &:before {
            content: "";
            width: 10px;
            height: 10px;
            margin-right: 10px;
            position: static;
          }
        }
      }
    }
  }
  ul {
    font-size: 20px;
    font-weight: $fw-regular;
    counter-reset: section;
    list-style: none;
    padding: 0;
    padding-left: 31px;
    li {
      position: relative;
      padding-left: 20px;
      &:not(:first-child) {
        margin-top: 10px;
      }
      &:before {
        top: 7px;
        left: -26px;
        position: absolute;
        content: "";
        font-size: 11px;
        font-weight: $fw-light;
        background-color: $primary-color;
        width: 10px; height: 10px;
        display: inline-block;
        border-radius: 50%;
        text-align: center;
        line-height: 10px;
        color: white;
      }
      ul {
        padding-left: 5px;
        li {
          margin: 0;
          &:not(:first-child) {
            margin-top: 0;
          }
        }
      }
    }
  }

  p img {
    width: calc(50% - 15px);
    font-size: 0;
    float: left;
    margin-right: 15px;
    margin-bottom: 40px;
    margin-top: calc(40px - 1rem);
    @include media-breakpoint-down(md) {
      width: 100%;
      margin-top: 0;
      margin-right: 0;
      margin-bottom: 20px;
    }
    &:nth-child(2n + 2) {
      margin-right: 0;
      margin-left: 15px;
      @include media-breakpoint-down(md) {
        margin-left: 0;
      }
    }
  }
}

.p-gallery {
  margin-top: 60px;
}

.p-gallery__item {
  margin-bottom: 30px;
  a { outline: 0; }
  img {
    height: auto;
    max-width: 100%;
    display: block;
    margin: auto;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
}