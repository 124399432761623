.accordion {
  .card {
    border: 0;
  }

  .card-header {
    background-color: transparent;
    border-bottom: 2px solid $primary-color;
    padding: .75rem 1.25rem .75rem 2.5rem;
    position: relative;

    h3 {
      button {
        font-size: 20px;
        text-transform: uppercase;
        font-weight: $fw-bold;
        text-decoration: none;
        color: currentColor;
        letter-spacing: 4px;
        &.collapsed:before {
          transform: none;
        }
        &:before {
          content: "";
          background: url(../images/plus-icon.png) center no-repeat;
          width: 20px; height: 20px;
          transition: transform 250ms $ease-out;
          position: absolute;
          top: 0; bottom: 0;
          left: 1rem;
          margin: auto;
          backface-visibility: hidden;
          transform: rotate(45deg);
        }
      }
    }
  }

  .card-body {
    color: $white;
    font-size: 20px;
    background-color: $primary-color;
    li {
      padding: 2px 0;
    }
  }
}

.accordion-header {
  margin-bottom: 30px;

  img {
    height: auto;
    max-width: 100%;
  }
}