.c-downloads {
  width: 100%;
  margin-top: 40px;
  @include clearfix();
}

.c-download-btn {
  display: block;
  color: #000;
  text-decoration: none;
  background: url(../images/download-icon.png) left 40px center no-repeat,#66cccc;
  padding: 2.5rem 2rem 2.5rem 140px;
  font-size: 18px;
  @include media-breakpoint-down(sm) {
    background: url(../images/download-icon.png) top 40px center no-repeat,#66cccc;
    padding: 140px 2rem 3rem 2rem;
    text-align: center;
  }
  &:not(:first-child) {
    margin-top: 20px;
  }
  &:hover {
    text-decoration: none;
    background: url(../images/download-icon.png) left 40px center no-repeat,lighten(#66cccc, 3%);
    color: lighten(#000, 20%);
    @include media-breakpoint-down(sm) {
      background: url(../images/download-icon.png) top 40px center no-repeat,lighten(#66cccc, 3%);
    }
  }
}