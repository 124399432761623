.c-tab-box {
  position: absolute;
  z-index: 5;
  max-width: 440px;
  width: 100%;
  background-image: linear-gradient(-210deg, #66cccc 0%, #339999 100%);
  left: 18%;

  @include media-breakpoint-down(lg) {
    left: 40px;
  }

  @include media-breakpoint-down(md) {
    position: static;
    margin-bottom: 40px;
    max-width: calc(100% - 4rem);
    margin-left: 2rem;
    margin-right: 2rem;
  }
}

.c-tab-box__nav {
  display: flex;
  justify-content: space-between;
  a {
    padding: 1rem;
    background-color: white;
    color: #339999;
    display: block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
    text-decoration: none;
    &.is-active {
      background-color: transparent;
      color: white;
    }
    @include media-breakpoint-down(md) {
      background-color: #f3f3f3;
    }
  }
}

.c-tab-box__tabs {
  color: white;
  padding: 2rem;
}

.c-tab-box__tab {
  display: none;
  &.is-active {
    display: block;
  }
}

.c-tab-box__tab__content {
  text-transform: uppercase;

  @include media-breakpoint-down(md) {
    max-width: 440px;
    display: block;
    margin: auto;
  }

  h3 {
    font-size: 14px;
    font-weight: $fw-black;
    text-align: center;
  }
  ul {
    list-style: none;
    text-align: center;
    font-size: 14px;
    padding: 0;
    &:not(:first-of-type) {
      margin-top: 2rem;
    }
  }
  li {
    &:not(:first-child) {
      padding-top: 10px;
    }
  }
  p {
    line-height: 1.75;
    margin-bottom: 0;
  }
  a {
    color: currentColor;
    font-weight: $fw-bold;
  }
  small {
    font-size: 9px;
  }
}