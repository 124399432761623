body#tinymce {
  margin: 12px !important;
}

.mce-content-body {
  blockquote {
    background-color: #eef5ef;
    display: flex;
    padding: 1rem;
    align-items: center;
    p {
      &:last-child {
        margin: 0;
      }
    }
  }
}