.c-half-box {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  color: $white;
  background-color: $text-color;
  overflow: hidden;

  &:hover {
    .c-half-box__content {
      transform: translateY(-20px);
      text-shadow: 0 4px 8px rgba($text-color, 0.5);
    }

    .hover-bg {
      &:after {
        opacity: 0;
        transform: translateY(10%);
      }
    }

    img {
      opacity: 0.75;
      transform: scale(1.05);
    }
  }

  img {
    max-width: 100%;
    object-fit: cover;
    transition: opacity 450ms $ease-out, transform 450ms $ease-out;
    backface-visibility: hidden;
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }

  .hover-bg {
    content: "";
    position: absolute;
    width: 100%; height: 100%;
    backface-visibility: hidden;
    z-index: 5;
    &:after {
      content: "";
      position: absolute;
      width: 100%; height: 100%;
      background: url(../images/hover-bg.png) bottom center no-repeat;
      transition: opacity 750ms $ease-out, transform 450ms $ease-out;
      @include media-breakpoint-down(lg) {
        background-size: contain;
      }
    }
  }
}

.c-half-box__content {
  padding: 2rem 3rem;
  position: absolute;
  text-align: center;
  text-transform: uppercase;
  font-weight: $fw-black;
  pointer-events: none;
  z-index: 6;
  transition: transform 450ms $ease-out, text-shadow 450ms $ease-out;

  @include media-breakpoint-down(md) {
   padding: 2rem 1rem;
  }

  h3 {
    font-size: 26px;
    font-weight: inherit;
    letter-spacing: 5px;
    line-height: 1.5;
    @include media-breakpoint-down(lg) {
      font-size: 20px;
    }
    span {
      display: block;
      font-size: 16px;
      margin-bottom: 10px;
      letter-spacing: normal;
      @include media-breakpoint-down(lg) {
        font-size: 14px;
      }
    }
  }

  p {
    font-size: 16px;
    margin-top: 10px;
    @include media-breakpoint-down(lg) {
      font-size: 14px;
    }
  }

  a {
    color: currentColor;
    &:hover {
      text-decoration: none;
    }
  }
}