.zespol-box {
  margin-top: 40px;
  img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    @include media-breakpoint-down(md) {
      width: 100%;
      margin-top: 20px;
    }
  }
}

.zespol-box__content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 2rem 5rem;
  background-image: linear-gradient(-210deg, #339999 0%, #66cccc 100%);
  color: $white;
  @include media-breakpoint-down(md) {
    padding: 2rem;
  }
  p {
    font-size: 20px;
    line-height: 2;
    @include media-breakpoint-down(md) {
      line-height: 1.45;
      font-size: 18px;
    }
  }
}



.zespol-box__dopisek {
  font-size: 26px;
  font-weight: $fw-bold;
  text-align: center;
  width: calc(100% - 2rem);
  max-width: 666px;
  margin: 40px auto 0;
}