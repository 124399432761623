.c-button {
  font-size: 16px;
  font-weight: $fw-regular;
  background-color: $primary-color;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding: 10px 25px;
  color: $white;
  transition: background-color 350ms $ease-out, color 350ms $ease-out;
  border: 0;
  cursor: pointer;
  outline: 0;

  &:hover {
    color: $text-color;
    background-color: $white;
    text-decoration: none;
  }
}